<oux-spinner [show]="loading$ | async" [size]="'large'" [hasOverlay]="true"></oux-spinner>

<oux-modal-content-container>
  <oux-modal-content-header [setOptions]="headerContent"></oux-modal-content-header>
  <oux-modal-content-article>
    <div class="body">
      <div class="add">
        <div class="add__label">User Id</div>

        <div class="add__hint">You can assign an unlimited amount of user's to act on your behalf. To add multiple proxies, simply enter the users ID, then click 'Add Proxy' one user at a time.</div>

        <div class="add__control">
          <input type="text" name="proxy" autocomplete="off" placeholder="Search by User ID" [(ngModel)]="newProxy" (ngModelChange)="onNewProxyChange()" (keyup.enter)="addProxy()" />
          <i *ngIf="newProxy" class="icon icon-close" (click)="clearNewProxyValue()"></i>
        </div>
      </div>

      <form [formGroup]="form">
        <div class="expiration-date">
          <div class="expiration-date-label">Expiration Date</div>
         
          <div class="date-picker">
            <oux-date-picker [options]="datePickerOptions" formControlName="expirationDate"></oux-date-picker>
          </div>
  
          <oux-button class="add__assign" [options]="addProxyButton" (trigger)="addProxy()"></oux-button>
        </div>
      </form>

      <ng-container *ngIf="!isValidExpiryDate">
        <oux-alert class="standard-alert" [theme]="'warning'" [icon]="'icon-exclamation-triangle'">
          <oux-alert-message>Please select a valid expiration date.
          </oux-alert-message>
        </oux-alert>
      </ng-container>

      <div class="proxies">
        <h3>Currently Assigned</h3>

        <div class="proxy" *ngFor="let proxy of (proxies$ | async)">
          <div class="proxy__name">{{proxy.name}} ({{proxy.emailAddress}})  |  Expiration Date: {{(proxy?.expiryDate | dateFormat) || 'NA'}}</div>
          
          <oux-button [options]="removeProxyButton" [theme]="'neutral'" (trigger)="removeProxy(proxy)"></oux-button>
        </div>

        <div *ngIf="!hasProxies" class="proxy">No proxies assigned.</div>
      </div>
    </div>

    <div class="footer">
      <oux-button
        [options]="closeButton"
        [theme]="'primary'" 
        (trigger)="close()"> 
      </oux-button>
    </div>
  </oux-modal-content-article>
</oux-modal-content-container>