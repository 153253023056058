import { OrdersSearchTableExport } from "../../interface/partials/order-search-table-export"; 

export class OrdersSearchTableExportModel implements OrdersSearchTableExport {
    public BACKLOG: number;
    public BOOKING: number;
    public COMP_PLAN_ID: number;
    public CURRENCY_CODE: string;
    public DEAL_ID: string;
    public EMPLOYEE_ID: string;
    public END_CUSTOMER: string;
    public ERP_POS_FLAG: string;
    public EXTD_MY_PAYOUT_AMOUNT_USD: number;
    public MY_PAYOUT_AMOUNT_USD: number;
    public NODE_NAME: string;
    public NODE_NAME_DESC: string;
    public ORDER_TYPE: string;
    public PEC: string;
    public PLAN_ELEMENT_DESCR: string;
    public PO_NUMBER: string;
    public QUOTA_ID: number;
    public REVENUE: number;
    public REVENUE_MULTI: number;
    public SELL_TYPE: string;
    public SO_DATE: string;
    public SO_NUMBER: number;
    public SRP_GOAL_HEADER_ID: number;
    public SRP_GOAL_QUOTA_ID: number;
    public TERRITORY_TYPE_CODE: string;
    public TYPE: string;
    public ACV_AMOUNT: number;
    public TCV_AMOUNT: number;
    public AMP_IDENTIFIER: string;
    public TOTAL_VALUE: number;
    public ANNUAL_VALUE: number;
    public IACV_BOOKING_AMOUNT: number;
    public RENEWAL_BOOKING_AMOUNT: number;
    public BOOKING_ADJ_AMOUNT: number;
    public METRIC_FLAG: string;
    public ENABLE_ACV_TCV: string;

    public END_CUSTOMER_NAME: string;
    public BOOKING_ADJ_USD_AMOUNT: number;
    public XSELL_IACV_USD_AMOUNT: number;
    public RENEWAL_BOOKING_USD_AMOUNT: number;
    public IACV_BOOKING_USD_AMOUNT: number;
    public NEW_LOGO_IACV_USD_AMOUNT: number;
    public UPSELL_IACV_USD_AMOUNT: number;
    public METRIC_MANUAL_REVENUE_AMOUNT: number;
    public NODENAMEDESC: string;
    public PAY_PERIODS: string;

  // optional - not part of original model - currently used for export
  public REVENUE_FOR_BACKLOG?: number;

  constructor(args : Partial<OrdersSearchTableExportModel>) {
    Object.assign(this, args);
  }

} 