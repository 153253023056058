import { Component, OnDestroy, OnInit } from '@angular/core';
import { OuxButtonOptions, OuxButtonShape, OuxButtonSize, OuxButtonStyle, OuxModalService, OuxThemeType, OuxToastOptions, OuxToastService, OuxDatePickerOptions } from '@cisco/oux-common';
import { BehaviorSubject, EMPTY, Subscription, throwError } from 'rxjs';
import { catchError, finalize, map, switchMap, tap } from 'rxjs/operators';
import { CsUserList } from 'src/app/shared/models/interface/partials/cs-user-list';
import { ProxyService } from 'src/app/shared/services/proxy.service';
import { ProxyStore } from 'src/app/shared/stores/proxy.store';
import { FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'proxy-modal',
  templateUrl: './proxy-modal.component.html',
  styleUrls: ['./proxy-modal.component.scss'],
  host: {
    'class': 'proxy-modal'
  }
})
export class ProxyModalComponent implements OnInit, OnDestroy {
  private subscriptions: Subscription[] = [];

  public headerContent: { icon: string, heading: string } = {
    icon: 'icon-raise-hand',
    heading: 'Assign Proxy'
  };

  public addProxyButton = new OuxButtonOptions({
    style: OuxButtonStyle.Outline,
    label: 'Add Proxy',
    type: 'button',
    disabled: true,
    shape: OuxButtonShape.Square
  });

  public removeProxyButton = new OuxButtonOptions({
    icon: 'icon-trash',
    style: OuxButtonStyle.Flat,
    size: OuxButtonSize.Small,
    label: '',
    type: 'button',
    shape: OuxButtonShape.Circle
  });

  public closeButton = new OuxButtonOptions({
    style: OuxButtonStyle.Flat,
    label: 'Close',
    type: 'button',
    shape: OuxButtonShape.Square
  });

  public newProxy: string = '';
  public hasProxies: boolean = false;
  public isValidExpiryDate: boolean = true;

  public proxies$ = this.proxyStore.proxies$
    .pipe(
      map(proxies => proxies?.P_CSUSERLIST || []),
      tap(proxies => this.hasProxies = proxies.length > 0)
    );

  public loading$ = new BehaviorSubject<boolean>(true);

  public form = new FormGroup({
    expirationDate: new FormControl()
  });

  ////////////////////////////////////////////////
  // Dependency Injection
  ////////////////////////////////////////////////

  constructor(
    private proxyService: ProxyService,
    private ouxModalSvc: OuxModalService,
    private proxyStore: ProxyStore,
    private toastService: OuxToastService
  ) {
    
  }

  ////////////////////////////////////////////////
  // Lifecycle Hooks
  ////////////////////////////////////////////////

  public ngOnInit(): void {
    this.loading$.next(true);

    this.subscriptions.push(this.proxyService.fetch()
      .pipe(
        finalize(() => this.loading$.next(false))
      )
      .subscribe()
    );
  }

  public ngOnDestroy(): void {
    this.subscriptions.forEach(x => x.unsubscribe());
    this.subscriptions = [];
  }

  ////////////////////////////////////////////////
  // Public Methods
  ////////////////////////////////////////////////

  public close(): void {
    this.ouxModalSvc.closeModal();
  }

  public datePickerOptions = new OuxDatePickerOptions({
    closeOnSelect: true
  });

  private getExpirationDate(): any {
    let rawValue = this.form?.getRawValue();

    if (!rawValue) {
      return null;
    }

    return rawValue.expirationDate?.format('MM/DD/YYYY') || '';
  }

  public addProxy(): void {
    if (!this.newProxy) {
      return;
    }

    let expiryDate = this.getExpirationDate();

    if (this.isPreviousDate(expiryDate)) {
      return;
    }

    this.loading$.next(true);

    this.form?.reset();

    this.subscriptions.push(this.proxyService.add(this.newProxy, expiryDate)
      .pipe(
        catchError(error => {
          this.toastService.addToast(new OuxToastOptions({
            header: 'Failed to add proxy',
            message: error?.error?.message || error?.P_ERROR_MESSAGE || `Failed to add proxy user: ${this.newProxy}.`,
            icon: 'icon-error-outline',
            autoDismiss: true,
            dismissTimeout: 15,
            theme: OuxThemeType.Error
          }));

          return throwError(error);
        }),
        tap(() => this.newProxy = ''),
        switchMap(() => this.proxyService.fetch()),
        finalize(() => this.loading$.next(false))
      )
      .subscribe()
    );
  }

  public isPreviousDate(inputDate: string): boolean {
    const today = new Date();
    const selectedDate = new Date(inputDate);

    // Set the time part to 0 for both dates to compare only the date part
    today.setHours(0, 0, 0, 0);
    selectedDate.setHours(0, 0, 0, 0);

    this.isValidExpiryDate = (selectedDate < today) ? false : true;
    return selectedDate < today;
  }

  public removeProxy(proxy: CsUserList): void {
    this.loading$.next(true);

    this.subscriptions.push(this.proxyService.delete(proxy.emailAddress)
      .pipe(
        catchError(error => {
          this.toastService.addToast(new OuxToastOptions({
            header: 'Failed to delete proxy',
            message: error?.error?.message || error?.P_ERROR_MESSAGE || `Failed to delete proxy user: ${proxy.emailAddress}.`,
            icon: 'icon-error-outline',
            autoDismiss: true,
            dismissTimeout: 15,
            theme: OuxThemeType.Error
          }));

          return throwError(error);
        }),
        switchMap(() => this.proxyService.fetch()),
        finalize(() => this.loading$.next(false))
      )
      .subscribe()
    );
  }

  public onNewProxyChange(): void {
    this.addProxyButton.disabled = this.newProxy ? false : true;
  }

  public clearNewProxyValue(): void {
    this.newProxy = '';

    this.onNewProxyChange();
  }

  ////////////////////////////////////////////////
  // Private Methods
  ////////////////////////////////////////////////

}